import React from 'react';

import { Circle, Label } from './styledComponents';

type Props = {
  counter: number;
};

const Badge = ({ counter }: Props) => (
  <Circle>
    <Label>{counter}</Label>
  </Circle>
);

export default Badge;
