import {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { withTheme } from 'styled-components';

import { Color, TextStyle, ThemeProps } from '../theme';
import { LoadableText, StyledText } from './styledComponents';

export interface Props extends ThemeProps {
  styleKey?: TextStyle;
  color?: Color;
  isLoading?: boolean;
  className?: string;
  numberOfLines?: number;
  ellipsizeMode?: string;
  onPress?: MouseEventHandler;
  accessibilityLabel?: string;
  style?: CSSProperties | any;
  testID?: string;
}

const Text = ({
  children,
  theme,
  styleKey = TextStyle.BODY_1,
  color,
  isLoading,
  onPress,
  accessibilityLabel,
  style,
  testID,
  ...textProps
}: PropsWithChildren<Props>): ReactElement =>
  isLoading ? (
    <LoadableText
      className={textProps.className}
      color={color}
      {...textProps}
      style={style}
    >
      {children}
    </LoadableText>
  ) : (
    <StyledText
      data-testid={testID}
      className={textProps.className}
      styleKey={styleKey}
      color={color}
      onClick={onPress}
      {...textProps}
      style={style}
    >
      {children}
    </StyledText>
  );

export default withTheme(Text);
